@import url(https://fonts.googleapis.com/css?family=Open+Sans|Patua+One|Raleway|Comfortaa|DM+Sans:400,500,700);
.HeaderNav_HeaderNav__2yAsm .HeaderNav_Nav__2R_6k {
  /* background-color: rgb(141, 201, 241); */
  background-color: #ffffff !important;
  padding: 0;
  z-index: 50;
  background-attachment: fixed;
  box-shadow: inset 0px 0px 10px black;
  -webkit-animation-duration: 500ms !important;
          animation-duration: 500ms !important;
  -webkit-animation-fill-mode: both !important;
          animation-fill-mode: both !important;
}

.HeaderNav_HeaderNav__2yAsm .HeaderNav_Nav__2R_6k ul li {
  padding: 16px 0;
  border-radius: 0px;
}

@media (max-width: 992px) {
  .HeaderNav_HeaderNav__2yAsm .HeaderNav_Nav__2R_6k {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

/* From: https://codepen.io/designcouch/pen/Atyop */

.MobileNavToggle_MobileNavToggle__sMh7x {
  margin: 21px 0;
  width: 36px;
  height: 32px;
  position: absolute;
  right: 10%;
  top: 0;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 500;
}

@media (max-width: 567px) {
  /* .MobileNavToggle {
		margin-right: 20px;
	} */
}

.MobileNavToggle_MobileNavToggle__sMh7x span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #4a4a4a;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.MobileNavToggle_MobileNavToggle__sMh7x.MobileNavToggle_open__ZEi_0 span {
  height: 8px;
}

/* Icon 3 */

.MobileNavToggle_MobileNavToggle__sMh7x span:nth-child(1) {
  top: 0px;
}

.MobileNavToggle_MobileNavToggle__sMh7x span:nth-child(2),
.MobileNavToggle_MobileNavToggle__sMh7x span:nth-child(3) {
  top: 12px;
}

.MobileNavToggle_MobileNavToggle__sMh7x span:nth-child(4) {
  top: 24px;
}

.MobileNavToggle_MobileNavToggle__sMh7x.MobileNavToggle_open__ZEi_0 span:nth-child(1) {
  top: 12px;
  width: 0%;
  left: 50%;
}

.MobileNavToggle_MobileNavToggle__sMh7x.MobileNavToggle_open__ZEi_0 span:nth-child(2) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.MobileNavToggle_MobileNavToggle__sMh7x.MobileNavToggle_open__ZEi_0 span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.MobileNavToggle_MobileNavToggle__sMh7x.MobileNavToggle_open__ZEi_0 span:nth-child(4) {
  top: 12px;
  width: 0%;
  left: 50%;
}

.Logo_Logo__2-x12 {
  background-color: transparent;
  /* padding: 8px; */
  height: 40px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  vertical-align: center;
  justify-content: center;
  margin-left: 10px; }

.Logo_Logo__2-x12 img {
  width: 40px;
  height: 40px; }

.Logo_LogoSVG__SxAw2 {
  width: 100%;
  height: 100%; }

.Logo_LogoTitle__SDTO0 {
  font-family: 'Comfortaa', arial;
  color: #333;
  margin-left: 10px;
  font-size: 1.5em;
  line-height: 44px;
  display: flex;
  vertical-align: center;
  justify-content: center; }

.Logo_LogoSVG__SxAw2 circle {
  stroke-width: 15px;
  fill: none;
  stroke-dasharray: 25px 15px; }

.Logo_LogoSVG__SxAw2 circle:nth-child(even) {
  -webkit-animation: Logo_orbit__RJ4IL 1s infinite linear;
          animation: Logo_orbit__RJ4IL 1s infinite linear; }

.Logo_LogoSVG__SxAw2 circle:nth-child(odd) {
  animation: Logo_orbit__RJ4IL 1s infinite reverse linear; }

.MobileNavMenu_MobileNavMenu__3ZAaU {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  -webkit-animation-delay: 500ms;
          animation-delay: 500ms;
}

.MobileNavMenu_MobileNavMenu__3ZAaU ul {
  text-align: center;
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.MobileNavMenu_MobileNavMenu__3ZAaU ul li {
  font-family: "Patua One", cursive;
  font-size: 32px;
  letter-spacing: 1.4px;
  height: 25%;
  display: block;
  background-color: rgba(12, 12, 12, 0.95);
  -webkit-animation-duration: 200ms;
          animation-duration: 200ms;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  border-bottom: 1px solid black;
}

@media (max-width: 320px) {
  .MobileNavMenu_MobileNavMenu__3ZAaU ul li {
    font-size: 26px;
  }
}

.MobileNavMenu_Open__FEA7V:nth-child(1) {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
}
.MobileNavMenu_Open__FEA7V:nth-child(2) {
  -webkit-animation-delay: 50ms;
          animation-delay: 50ms;
}
.MobileNavMenu_Open__FEA7V:nth-child(3) {
  -webkit-animation-delay: 100ms;
          animation-delay: 100ms;
}
.MobileNavMenu_Open__FEA7V:nth-child(4) {
  -webkit-animation-delay: 150ms;
          animation-delay: 150ms;
}
.MobileNavMenu_Open__FEA7V:nth-child(5) {
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
}
.MobileNavMenu_Open__FEA7V:nth-child(6) {
  -webkit-animation-delay: 250ms;
          animation-delay: 250ms;
}
.MobileNavMenu_Open__FEA7V:nth-child(7) {
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}

.MobileNavMenu_Close__agWLm:nth-child(1) {
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}
.MobileNavMenu_Close__agWLm:nth-child(2) {
  -webkit-animation-delay: 250ms;
          animation-delay: 250ms;
}
.MobileNavMenu_Close__agWLm:nth-child(3) {
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
}
.MobileNavMenu_Close__agWLm:nth-child(4) {
  -webkit-animation-delay: 150ms;
          animation-delay: 150ms;
}
.MobileNavMenu_Close__agWLm:nth-child(5) {
  -webkit-animation-delay: 100ms;
          animation-delay: 100ms;
}
.MobileNavMenu_Close__agWLm:nth-child(6) {
  -webkit-animation-delay: 50ms;
          animation-delay: 50ms;
}
.MobileNavMenu_Close__agWLm:nth-child(7) {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
}

.MobileNavMenu_MobileNavMenu__3ZAaU ul li button {
  background-color: transparent;
  border: none;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  line-height: 100%;
  color: white;
  text-shadow: 1px 1px #000;
  box-shadow: inset 0px 0px 10px #111;
  cursor: pointer;
}

.MobileNavMenu_MobileNavMenu__3ZAaU ul li button:hover,
.MobileNavMenu_MobileNavMenu__3ZAaU ul li button:active,
.MobileNavMenu_MobileNavMenu__3ZAaU ul li button.MobileNavMenu_active__3WDwr {
  text-decoration: none;
  color: rgb(196, 207, 34) !important;
  background-color: rgba(30, 144, 255, 0.9);
}

.MobileNavMenu_MobileNavMenu__3ZAaU ul li:hover {
  background-color: rgba(30, 144, 255, 0.9);
}

.MobileNavMenu_MobileNavMenu__3ZAaU ul li button span {
  position: absolute;
  top: 50%;
  width: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Footer_Footer__QsHih {
  margin: 0;
  background-color: #333;
  padding: 60px 0; }
  .Footer_Footer__QsHih ul {
    padding: 0;
    margin: 0;
    display: inline;
    list-style-type: none; }
    .Footer_Footer__QsHih ul li {
      display: inline; }

.Footer_SocialRow__3Y0Tn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.Footer_SiteMap__3p0l9 li:first-child {
  border-top: 1px solid black; }

.Footer_SiteMap__3p0l9 li:hover {
  padding-left: 10px; }

.Footer_SiteMap__3p0l9 li:hover a {
  color: #d36703 !important; }

.Footer_SiteMap__3p0l9 li a {
  text-decoration: none !important;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: #343a40 !important; }

.Footer_FooterBottom__1tSwR {
  background-color: #266616 !important;
  border-top: 1px solid black;
  color: #ccc;
  padding-top: 15px;
  padding-bottom: 15px; }

.Footer_FooterBottom__1tSwR p {
  margin: 0;
  text-align: center;
  font-size: 14px; }

.Footer_FooterBottom__1tSwR a {
  color: #c8c8c8; }

.Footer_FooterContactWrap__3K1lo {
  font-size: 16px;
  color: white; }

.Footer_FooterContactWrap__3K1lo a {
  color: #3f723e; }

.Footer_FooterVeryBottom__3w5D1 {
  background-color: #305ea8;
  text-align: center;
  border-top: 1px solid black;
  color: #c8c8c8;
  padding: 6px 0;
  font-size: 12px;
  font-size: "Raleway"; }

.Footer_FooterVeryBottom__3w5D1 a {
  color: #c8c8c8;
  font-weight: none; }

.Home_TextVerticalCenter__3e7bv {
  padding-bottom: 65%; }
  @media only screen and (min-width: 768px) {
    .Home_TextVerticalCenter__3e7bv {
      padding-bottom: 25%; } }
  @media only screen and (min-width: 992px) {
    .Home_TextVerticalCenter__3e7bv {
      padding-bottom: 0%; } }

.Home_QuoteRequestConfirmationSnackbar__2hwG3 {
  top: 80px !important; }
  .Home_QuoteRequestConfirmationSnackbar__2hwG3 .Home_QuoteRequestConfirmationAlert__1Fbj9 {
    border-radius: 0;
    background-color: #333;
    color: #b7dfb9;
    padding: 10px;
    margin: 0 10px;
    z-index: 5000; }
    .Home_QuoteRequestConfirmationSnackbar__2hwG3 .Home_QuoteRequestConfirmationAlert__1Fbj9 h2 {
      color: #b7dfb9;
      font-size: 20px;
      text-align: center;
      margin-bottom: 0; }
      @media only screen and (min-width: 768px) {
        .Home_QuoteRequestConfirmationSnackbar__2hwG3 .Home_QuoteRequestConfirmationAlert__1Fbj9 h2 {
          font-size: 24px; } }
    .Home_QuoteRequestConfirmationSnackbar__2hwG3 .Home_QuoteRequestConfirmationAlert__1Fbj9 hr {
      margin: 5px 0; }
    .Home_QuoteRequestConfirmationSnackbar__2hwG3 .Home_QuoteRequestConfirmationAlert__1Fbj9 p {
      font-family: 'DM Sans', sans-serif;
      font-size: 14px; }
      @media only screen and (min-width: 768px) {
        .Home_QuoteRequestConfirmationSnackbar__2hwG3 .Home_QuoteRequestConfirmationAlert__1Fbj9 p {
          font-size: 16px; } }
    .Home_QuoteRequestConfirmationSnackbar__2hwG3 .Home_QuoteRequestConfirmationAlert__1Fbj9 button {
      position: absolute;
      top: -10px;
      right: -10px; }
      .Home_QuoteRequestConfirmationSnackbar__2hwG3 .Home_QuoteRequestConfirmationAlert__1Fbj9 button:hover {
        color: #ccc; }

.Home_Home__3mUzE .Home_Header__258Vc {
  display: table;
  position: relative;
  height: 100vh;
  width: 100vw;
  position: relative;
  background: url(/assets/img/home-landing.jpg) no-repeat center center scroll;
  background-size: cover; }
  .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.6);
    border: 1px solid ghostwhite;
    padding-top: 4%;
    padding-bottom: 4%;
    width: 90%;
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
    @media only screen and (min-width: 768px) {
      .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo {
        width: 80%;
        margin-left: 5%;
        -webkit-transform: translateX(0%);
                transform: translateX(0%); } }
    @media only screen and (min-width: 992px) {
      .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo {
        width: 50%; } }
    .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo a {
      margin-top: 15px; }
    .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo h1 {
      position: relative;
      margin: 0;
      padding: 0;
      font-size: 2em; }
      @media only screen and (min-width: 480px) {
        .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo h1 {
          font-size: 3.5em; } }
      @media only screen and (min-width: 768px) {
        .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo h1 {
          font-size: 4.25em; } }
      @media only screen and (min-width: 1200px) {
        .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo h1 {
          line-height: 1em;
          font-size: 5.25em; } }
      .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo h1 strong {
        width: 100%;
        display: inline-block;
        font-family: 'Comfortaa', arial;
        -webkit-font-smoothing: antialiased;
        text-shadow: rgba(0, 0, 0, 0.5) -1px 0, rgba(0, 0, 0, 0.3) 0 -1px, rgba(255, 255, 255, 0.5) 0 1px, rgba(0, 0, 0, 0.3) -1px -2px;
        color: #2a75b3; }
        .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo h1 strong::-moz-selection {
          background: rgba(255, 255, 0, 0.4); }
        .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo h1 strong::selection {
          background: rgba(255, 255, 0, 0.4); }
    .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo h3 {
      margin: 0;
      padding: 0;
      position: relative;
      font-family: 'Raleway', sans-serif;
      font-weight: none;
      margin-top: 5px;
      font-size: 1.5em; }
      @media only screen and (min-width: 480px) {
        .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo h3 {
          font-size: 2em; } }
      @media only screen and (min-width: 768px) {
        .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo h3 {
          font-size: 2.75em; } }
      .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo h3 em {
        font-style: normal;
        font-weight: none;
        width: 100%;
        display: inline-block;
        -webkit-font-smoothing: antialiased;
        text-shadow: rgba(0, 0, 0, 0.5) -1px 0, rgba(0, 0, 0, 0.3) 0 -1px, rgba(255, 255, 255, 0.5) 0 1px, rgba(0, 0, 0, 0.3) -1px -2px;
        color: #ff6e00; }
        .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo h3 em::-moz-selection {
          background: rgba(150, 0, 150, 0.4); }
        .Home_Home__3mUzE .Home_Header__258Vc .Home_LandingWrap__1DQOo h3 em::selection {
          background: rgba(150, 0, 150, 0.4); }

.Home_Home__3mUzE .Home_About__2W4Qm {
  padding-top: 40px;
  background: -webkit-linear-gradient(top, #2a75b3 25%, #fff 10%);
  background: linear-gradient(180deg, #2a75b3 25%, #fff 10%);
  background-repeat: no-repeat; }
  @media only screen and (min-width: 768px) {
    .Home_Home__3mUzE .Home_About__2W4Qm {
      background: -webkit-linear-gradient(right, #fff 65%, #2a75b3 25%);
      background: linear-gradient(-90deg, #fff 65%, #2a75b3 25%);
      padding: 60px 0; } }
  @media only screen and (min-width: 992px) {
    .Home_Home__3mUzE .Home_About__2W4Qm .Home_AboutContainer__1S4eb {
      max-width: 1140px; } }
  @media only screen and (min-width: 768px) {
    .Home_Home__3mUzE .Home_About__2W4Qm .Home_ImgNameCol__1Vpxm {
      display: flex;
      justify-content: flex-end;
      padding-right: 40px; } }
  .Home_Home__3mUzE .Home_About__2W4Qm .Home_ImgNameCol__1Vpxm .Home_ImgNameWrap__3yYgm {
    position: relative;
    padding: 0;
    margin: 0; }
    .Home_Home__3mUzE .Home_About__2W4Qm .Home_ImgNameCol__1Vpxm .Home_ImgNameWrap__3yYgm .Home_ImgNameWrapInner__bRQT6 {
      margin: 0;
      padding: 0; }
      .Home_Home__3mUzE .Home_About__2W4Qm .Home_ImgNameCol__1Vpxm .Home_ImgNameWrap__3yYgm .Home_ImgNameWrapInner__bRQT6 .Home_Caption__kJVyr {
        position: absolute;
        color: #fff;
        top: 0;
        left: 0;
        margin-left: 30px;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        -webkit-transform-origin: 0 0;
                transform-origin: 0 0;
        white-space: nowrap; }
      .Home_Home__3mUzE .Home_About__2W4Qm .Home_ImgNameCol__1Vpxm .Home_ImgNameWrap__3yYgm .Home_ImgNameWrapInner__bRQT6 .Home_Img__2shxw {
        width: 90%;
        margin-left: 10%; }
        @media only screen and (min-width: 768px) {
          .Home_Home__3mUzE .Home_About__2W4Qm .Home_ImgNameCol__1Vpxm .Home_ImgNameWrap__3yYgm .Home_ImgNameWrapInner__bRQT6 .Home_Img__2shxw {
            max-width: 396px;
            max-height: 528px; } }

.Home_Home__3mUzE .Home_Services__16mzA .Home_ServicesWrapper__FYGe1 {
  padding-top: 20px;
  padding-bottom: 40px; }
  @media only screen and (min-width: 768px) {
    .Home_Home__3mUzE .Home_Services__16mzA .Home_ServicesWrapper__FYGe1 {
      margin-left: 60px; } }

.Home_Home__3mUzE .Home_Services__16mzA .Home_ServiceItem__1ledq {
  color: #fff;
  margin-top: 20px;
  padding: 0 20px; }
  @media only screen and (min-width: 768px) {
    .Home_Home__3mUzE .Home_Services__16mzA .Home_ServiceItem__1ledq {
      padding: 0; } }
  .Home_Home__3mUzE .Home_Services__16mzA .Home_ServiceItem__1ledq .Home_ServiceItemIcon__w-Hsl {
    width: 60px;
    height: 60px;
    float: left;
    fill: white;
    margin-right: 20px; }
    @media only screen and (min-width: 768px) {
      .Home_Home__3mUzE .Home_Services__16mzA .Home_ServiceItem__1ledq .Home_ServiceItemIcon__w-Hsl {
        width: 40px;
        height: 40px; } }
  .Home_Home__3mUzE .Home_Services__16mzA .Home_ServiceItem__1ledq .Home_ServiceItemHeader__txsbT {
    color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 1.25em; }
    @media only screen and (min-width: 768px) {
      .Home_Home__3mUzE .Home_Services__16mzA .Home_ServiceItem__1ledq .Home_ServiceItemHeader__txsbT {
        height: 40px;
        font-size: 1.25em; } }
  .Home_Home__3mUzE .Home_Services__16mzA .Home_ServiceItem__1ledq .Home_ShortLine__2IE6I {
    max-width: 80%;
    background-color: #fff;
    margin: 25px auto 0 auto; }
  .Home_Home__3mUzE .Home_Services__16mzA .Home_ServiceItem__1ledq .Home_ServiceItemHeader__txsbT {
    margin-bottom: 0px; }

.Home_Home__3mUzE .Home_Portfolio__1Nfz8 .Home_ImgPortfolioCol__3sMGW {
  background: url(/assets/img/office-collaboration.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 360px;
  padding: 0;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .Home_Home__3mUzE .Home_Portfolio__1Nfz8 .Home_ImgPortfolioCol__3sMGW {
      min-height: 500px; } }

.Home_Home__3mUzE .Home_CallToAction__3g-30 {
  padding: 80px 0; }
  .Home_Home__3mUzE .Home_CallToAction__3g-30 .Home_CallToActionWrapper__1dgQ8 {
    width: 80%;
    margin: auto; }
  .Home_Home__3mUzE .Home_CallToAction__3g-30 .Home_CallToActionHeader__3hPCP {
    font-family: 'DM Sans', sans-serif;
    color: #fff; }
  .Home_Home__3mUzE .Home_CallToAction__3g-30 button {
    margin-top: 10px; }

.Home_Home__3mUzE .Home_Testimonials__3TMgX .Home_ImgTestimonialsCol__3UJq2 {
  background: url(/assets/img/office-party.jpeg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 370px;
  padding: 0;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .Home_Home__3mUzE .Home_Testimonials__3TMgX .Home_ImgTestimonialsCol__3UJq2 {
      min-height: 580px; } }

.Button_Primary__FB33F {
  position: relative;
  color: ghostwhite;
  background-color: #2a75b3;
  border-radius: 0px;
  margin: 0px 10px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.Button_Secondary__TPJaW {
  position: relative;
  color: ghostwhite;
  background-color: #2b93d8;
  border-radius: 0px;
  margin: 0px 10px;
  border: 1px solid #1c70f8;
  box-shadow: 0px 0px 10px 0px black;
  text-shadow: 1px 1px 1px #222;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.Button_Danger__2Z9P- {
  position: relative;
  color: ghostwhite;
  background-color: #af2f35;
  border-radius: 0px;
  margin: 0px 10px;
  border: 1px solid #8a1726;
  box-shadow: 0px 0px 10px 0px black;
  text-shadow: 1px 1px 1px #222;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.Button_XL__2rz__ {
  padding: 16px;
  font-size: 1.75rem; }

.TestimonialsSlider_Carousel__3StvO .TestimonialsSlider_CarouselControl__1HlbV {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #999;
  text-shadow: none;
  top: 4px; }
  .TestimonialsSlider_Carousel__3StvO .TestimonialsSlider_CarouselControl__1HlbV.TestimonialsSlider_CarouselLeft__2YHK6 {
    left: auto;
    right: 40px;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%); }
  .TestimonialsSlider_Carousel__3StvO .TestimonialsSlider_CarouselControl__1HlbV.TestimonialsSlider_CarouselRight__2IVWG {
    right: 0;
    left: auto;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%); }

.TestimonialsSlider_Carousel__3StvO .TestimonialsSlider_CarouselItem__3GAt1 {
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  min-height: 340px; }
  .TestimonialsSlider_Carousel__3StvO .TestimonialsSlider_CarouselItem__3GAt1 .TestimonialsSlider_ImgBox__2JP6u {
    width: 145px;
    height: 145px;
    margin: 0 auto;
    border-radius: 50%; }
    .TestimonialsSlider_Carousel__3StvO .TestimonialsSlider_CarouselItem__3GAt1 .TestimonialsSlider_ImgBox__2JP6u img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 50%; }
  .TestimonialsSlider_Carousel__3StvO .TestimonialsSlider_CarouselItem__3GAt1 .TestimonialsSlider_Testimonial__1i5l1 {
    padding: 10px 0 0 0; }
  .TestimonialsSlider_Carousel__3StvO .TestimonialsSlider_CarouselItem__3GAt1 .TestimonialsSlider_Overview__1vEmV {
    text-align: center;
    padding-bottom: 5px;
    margin-bottom: 0; }
  .TestimonialsSlider_Carousel__3StvO .TestimonialsSlider_CarouselItem__3GAt1 .TestimonialsSlider_StarRating__3Segq i {
    font-size: 18px;
    color: #ffdc12; }

.QuoteModal_RequestQuoteModal__1-LWe .QuoteModal_RequestQuoteModalHeader__3jQwH {
  border: none; }
  .QuoteModal_RequestQuoteModal__1-LWe .QuoteModal_RequestQuoteModalHeader__3jQwH span.QuoteModal_section-subheader__U7G8x {
    margin: 0;
    border-bottom: 1px solid #333;
    font-size: 1.5em; }

.QuoteModal_RequestQuoteModal__1-LWe .QuoteModal_RequestQuoteModalBody__3kCDp {
  padding-top: 0; }

.QuoteModal_RequestQuoteModal__1-LWe .QuoteModal_IconCol__3QamT {
  text-align: center;
  flex: 0 0 35px; }



/* vendor fonts */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  padding: 0;
  margin: 0;
  font-family: "DM Sans", sans-serif; }

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-family: "DM Sans", sans-serif; }

#root {
  max-width: 100vw;
  max-height: 100vh;
  padding: 0;
  margin: 0;
  text-rendering: geometricPrecision;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

#root::-webkit-scrollbar {
  width: 12px;
  -webkit-appearance: none; }

/* Track */
#root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background: rgba(0, 0, 0, 0.7); }

/* Handle */
#root::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: #68a7db;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); }

#root::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 0, 0.7); }

.section-header {
  font-weight: 700;
  color: #2a75b3;
  letter-spacing: 2px; }

.section-subheader {
  font-weight: 700;
  line-height: 1.2;
  font-size: 1.75em;
  margin: 0;
  margin-bottom: 20px; }
  @media only screen and (min-width: 768px) {
    .section-subheader {
      font-size: 1.95em; } }

.color-brand-blue {
  color: #2a75b3 !important; }

.color-white {
  color: #fff !important; }

.color-black {
  color: #333 !important; }

.bg-brand-blue {
  background-color: #2a75b3 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-black {
  background-color: #333 !important; }

#root main {
  margin-top: 70px; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DM Sans", sans-serif; }

a {
  cursor: pointer; }

.scroll-disabled {
  touch-action: none; }

/* Global Styles */
.text-vertical-center {
  display: table-cell;
  text-align: center;
  vertical-align: middle; }

footer {
  padding: 100px 0; }

img {
  width: 100%;
  height: auto; }

.link-brand {
  border-bottom-color: #68a7db;
  color: #68a7db;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

