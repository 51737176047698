@import "../../../../assets/scss/colors";

.RequestQuoteModal {
	.RequestQuoteModalHeader {
		border: none;
		span.section-subheader {
			margin: 0;
			border-bottom: 1px solid $black;
			font-size: 1.5em;
		}
	}
	.RequestQuoteModalBody {
		padding-top: 0;
	}

	.IconCol {
		text-align: center;
		flex: 0 0 35px;
	}
}
