.Carousel {

  .CarouselControl {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #999;
    text-shadow: none;
    top: 4px;

    &.CarouselLeft {
      left: auto;
      right: 40px;
      background-image: -webkit-linear-gradient(left,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5) 100%);
    }

    &.CarouselRight {
      right: 0;
      left: auto;
      background-image: -webkit-linear-gradient(left,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5) 100%);
    }
  }

  .CarouselItem {
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    min-height: 340px;

    .ImgBox {
      width: 145px;
      height: 145px;
      margin: 0 auto;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 50%;
      }
    }

    .Testimonial {
      padding: 10px 0 0 0;
    }

    .Overview {
      text-align: center;
      padding-bottom: 5px;
      margin-bottom: 0;
    }

    .StarRating {
      i {
        font-size: 18px;
        color: #ffdc12;
      }
    }
  }
}