$brand-blue: #2a75b3;

$white: #fff;
$black: #333;
$grey: #ccc;

$error-red: #f44336;
$disabled-grey: #cacaca;
$success-dark-green: #4caf50;
$success-green: rgb(183, 223, 185);

:export {
	white: $white;
	black: $black;
	grey: $grey;
	brandBlue: $brand-blue;
	errorRed: $error-red;
	$disabled-grey: $disabled-grey;
	$success-dark-green: $success-dark-green;
	$success-green: $success-green;
}
