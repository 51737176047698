@import "../../../assets/scss/_colors.scss";

.Logo {
	background-color: transparent;
	/* padding: 8px; */
	height: 40px;
	box-sizing: border-box;
	border-radius: 5px;
	display: flex;
	vertical-align: center;
	justify-content: center;
	margin-left: 10px;
}

.Logo img {
	width: 40px;
	height: 40px;
}

.LogoSVG {
	width: 100%;
	height: 100%;
}

.LogoTitle {
	font-family: 'Comfortaa', arial;
	color: $black;
	margin-left: 10px;
	font-size: 1.5em;
	line-height: 44px;
	display: flex;
	vertical-align: center;
	justify-content: center;
}

.LogoSVG circle {
	stroke-width: 15px;
	fill: none;
	stroke-dasharray: 25px 15px;
}

.LogoSVG circle:nth-child(even) {
	animation: orbit 1s infinite linear;
}

.LogoSVG circle:nth-child(odd) {
	animation: orbit 1s infinite reverse linear;
}