.HeaderNav .Nav {
  /* background-color: rgb(141, 201, 241); */
  background-color: #ffffff !important;
  padding: 0;
  z-index: 50;
  background-attachment: fixed;
  box-shadow: inset 0px 0px 10px black;
  animation-duration: 500ms !important;
  animation-fill-mode: both !important;
}

.HeaderNav .Nav ul li {
  padding: 16px 0;
  border-radius: 0px;
}

@media (max-width: 992px) {
  .HeaderNav .Nav {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
