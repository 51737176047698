@import "../../assets/scss/_colors";
@import "../../assets/scss/_variables";

.TextVerticalCenter {
	padding-bottom: 65%;

	@media only screen and (min-width: $media-width-medium) {
		padding-bottom: 25%;
	}

	@media only screen and (min-width: $media-width-large) {
		padding-bottom: 0%;
	}
}

.QuoteRequestConfirmationSnackbar {
	top: 80px !important;

	.QuoteRequestConfirmationAlert {
		border-radius: 0;
		background-color: $black;
		color: $success-green;
		padding: 10px;
		margin: 0 10px;
		z-index: 5000;

		h2 {
			color: $success-green;
			font-size: 20px;
			text-align: center;
			margin-bottom: 0;

			@media only screen and (min-width: $media-width-medium) {
				font-size: 24px;
			}
		}

		hr {
			margin: 5px 0;
		}

		p {
			font-family: 'DM Sans', sans-serif;
			font-size: 14px;

			@media only screen and (min-width: $media-width-medium) {
				font-size: 16px;
			}
		}

		button {
			position: absolute;
			top: -10px;
			right: -10px;

			&:hover {
				color: $grey;
			}
		}
	}
}

.Home {
	.Header {
		display: table;
		position: relative;
		height: 100vh;
		width: 100vw;
		position: relative;
		background: url(/assets/img/home-landing.jpg) no-repeat center center scroll;
		background-size: cover;

		.LandingWrap {
			box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
			background-color: rgba(255, 255, 255, 0.6);
			border: 1px solid ghostwhite;
			padding-top: 4%;
			padding-bottom: 4%;
			width: 90%;
			margin-left: 50%;
			transform: translateX(-50%);

			@media only screen and (min-width: $media-width-medium) {
				width: 80%;
				margin-left: 5%;
				transform: translateX(0%);
			}

			@media only screen and (min-width: $media-width-large) {
				width: 50%;
			}

			a {
				margin-top: 15px;
			}

			h1 {
				position: relative;
				margin: 0;
				padding: 0;
				font-size: 2em;

				@media only screen and (min-width: $media-width-small) {
					font-size: 3.5em;
				}

				@media only screen and (min-width: $media-width-medium) {
					font-size: 4.25em;
				}

				@media only screen and (min-width: $media-width-largest) {
					line-height: 1em;
					font-size: 5.25em;
				}

				strong {
					width: 100%;
					display: inline-block;
					font-family: 'Comfortaa', arial;
					-webkit-font-smoothing: antialiased;
					text-shadow: rgba(0, 0, 0, 0.5) -1px 0, rgba(0, 0, 0, 0.3) 0 -1px, rgba(255, 255, 255, 0.5) 0 1px,
						rgba(0, 0, 0, 0.3) -1px -2px;
					color: $brand-blue;

					&::selection {
						background: rgba(255, 255, 0, 0.4);
					}
				}
			}

			h3 {
				margin: 0;
				padding: 0;
				position: relative;
				font-family: 'Raleway', sans-serif;
				font-weight: none;
				margin-top: 5px;
				font-size: 1.5em;

				@media only screen and (min-width: $media-width-small) {
					font-size: 2em;
				}

				@media only screen and (min-width: $media-width-medium) {
					font-size: 2.75em;
				}

				em {
					font-style: normal;
					font-weight: none;
					width: 100%;
					display: inline-block;
					-webkit-font-smoothing: antialiased;
					text-shadow: rgba(0, 0, 0, 0.5) -1px 0, rgba(0, 0, 0, 0.3) 0 -1px, rgba(255, 255, 255, 0.5) 0 1px,
						rgba(0, 0, 0, 0.3) -1px -2px;
					color: #ff6e00;
					&::selection {
						background: rgba(150, 0, 150, 0.4);
					}
				}
			}
		}
	}

	.About {
		padding-top: 40px;
		background: linear-gradient(180deg, $brand-blue 25%, $white 10%);
		background-repeat: no-repeat;

		@media only screen and (min-width: $media-width-medium) {
			background: linear-gradient(-90deg, $white 65%, $brand-blue 25%);
			padding: 60px 0;
		}

		.AboutContainer {
			@media only screen and (min-width: $media-width-large) {
				max-width: 1140px;
			}
		}

		.ImgNameCol {
			@media only screen and (min-width: $media-width-medium) {
				display: flex;
				justify-content: flex-end;
				padding-right: 40px;
			}

			.ImgNameWrap {
				position: relative;
				padding: 0;
				margin: 0;

				.ImgNameWrapInner {
					margin: 0;
					padding: 0;

					.Caption {
						position: absolute;
						color: $white;
						top: 0;
						left: 0;
						margin-left: 30px;
						transform: rotate(90deg);
						transform-origin: 0 0;
						white-space: nowrap;
					}
					.Img {
						width: 90%;
						margin-left: 10%;
						@media only screen and (min-width: $media-width-medium) {
							max-width: 396px;
							max-height: 528px;
						}
					}
				}
			}
		}
	}

	.Services {
		.ServicesWrapper {
			padding-top: 20px;
			padding-bottom: 40px;

			@media only screen and (min-width: $media-width-medium) {
				margin-left: 60px;
			}
		}

		.ServiceItem {
			color: $white;
			margin-top: 20px;
			padding: 0 20px;

			@media only screen and (min-width: $media-width-medium) {
				padding: 0;
			}

			.ServiceItemIcon {
				width: 60px;
				height: 60px;
				float: left;
				fill: white;
				margin-right: 20px;

				@media only screen and (min-width: $media-width-medium) {
					width: 40px;
					height: 40px;
				}
			}

			.ServiceItemHeader {
				color: $white;
				height: 60px;
				display: flex;
				align-items: center;
				font-weight: 300;
				font-size: 1.25em;

				@media only screen and (min-width: $media-width-medium) {
					height: 40px;
					font-size: 1.25em;
				}
			}

			.ShortLine {
				max-width: 80%;
				background-color: $white;
				margin: 25px auto 0 auto;
			}

			.ServiceItemHeader {
				margin-bottom: 0px;
			}
		}
	}

	.Portfolio {
		.ImgPortfolioCol {
			background: url(/assets/img/office-collaboration.jpg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			min-height: 360px;
			padding: 0;
			margin: 0;

			@media only screen and (min-width: $media-width-medium) {
				min-height: 500px;
			}
		}
	}

	.CallToAction {
		padding: 80px 0;

		.CallToActionWrapper {
			width: 80%;
			margin: auto;
		}

		.CallToActionHeader {
			font-family: 'DM Sans', sans-serif;
			color: $white;
		}

		button {
			margin-top: 10px;
		}
	}

	.Testimonials {
		.ImgTestimonialsCol {
			background: url(/assets/img/office-party.jpeg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			min-height: 370px;
			padding: 0;
			margin: 0;

			@media only screen and (min-width: $media-width-medium) {
				min-height: 580px;
			}
		}
	}
}
