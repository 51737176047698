@import "../../../assets/scss/colors";
@import "../../../assets/scss/variables";

.Footer {
  margin: 0;
  background-color: $black;
  padding: 60px 0;

  ul {
    padding: 0;
    margin: 0;
    display: inline;
    list-style-type: none;

    li {
      display: inline;
    }
  }
}

.SocialRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.SiteMap li:first-child {
  border-top: 1px solid black;
}

.SiteMap li:hover {
  padding-left: 10px;
}

.SiteMap li:hover a {
  color: rgb(211, 103, 3) !important;
}

.SiteMap li a {
  text-decoration: none !important;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  transition: all 0.5s ease;
  color: #343a40 !important;
}

.FooterBottom {
  background-color: rgb(38, 102, 22) !important;
  border-top: 1px solid black;
  color: #ccc;
  padding-top: 15px;
  padding-bottom: 15px;
}

.FooterBottom p {
  margin: 0;
  text-align: center;
  font-size: 14px;
}
.FooterBottom a {
  color: #c8c8c8;
}

.FooterContactWrap {
  font-size: 16px;
  color: white;
}

.FooterContactWrap a {
  color: #3f723e;
}

.FooterVeryBottom {
  background-color: #305ea8;
  text-align: center;
  border-top: 1px solid black;
  color: #c8c8c8;
  padding: 6px 0;
  font-size: 12px;
  font-size: "Raleway";
}

.FooterVeryBottom a {
  color: #c8c8c8;
  font-weight: none;
}
