@import '../../../assets//scss//_colors.scss';

.Primary {
  position: relative;
  color: ghostwhite;
  background-color: $brand-blue;
  border-radius: 0px;
  margin: 0px 10px;
  transition: all 0.3s ease;
}

.Secondary {
  position: relative;
  color: ghostwhite;
  background-color: rgb(43, 147, 216);
  border-radius: 0px;
  margin: 0px 10px;
  border: 1px solid rgb(28, 112, 248);
  box-shadow: 0px 0px 10px 0px black;
  text-shadow: 1px 1px 1px #222;
  transition: all 0.3s ease;
}

.Danger {
  position: relative;
  color: ghostwhite;
  background-color: rgb(175, 47, 53);
  border-radius: 0px;
  margin: 0px 10px;
  border: 1px solid rgb(138, 23, 38);
  box-shadow: 0px 0px 10px 0px black;
  text-shadow: 1px 1px 1px #222;
  transition: all 0.3s ease;
}

.XL {
  padding: 16px;
  font-size: 1.75rem;
}


