/* vendor fonts */
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Patua+One|Raleway|Comfortaa|DM+Sans:400,500,700');
@import "./assets/scss/_colors.scss";
@import "./assets/scss/_variables.scss";

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	padding: 0;
	margin: 0;
	font-family: "DM Sans", sans-serif;
}

body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	line-height: 1.5;
	font-family: "DM Sans", sans-serif;
}

#root {
	max-width: 100vw;
	max-height: 100vh;
	padding: 0;
	margin: 0;
	text-rendering: geometricPrecision;
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root::-webkit-scrollbar {
	width: 12px;
	-webkit-appearance: none;
}
/* Track */
#root::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 0px;
	background: rgba(0, 0, 0, 0.7);
}
/* Handle */
#root::-webkit-scrollbar-thumb {
	border-radius: 0px;
	background: lighten($brand-blue, 20%);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
#root::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(255, 255, 0, 0.7);
}

.section-header {
	font-weight: 700;
	color: $brand-blue;
	letter-spacing: 2px;
}
.section-subheader {
	font-weight: 700;
	line-height: 1.2;
	font-size: 1.75em;
	margin: 0;
	margin-bottom: 20px;
	@media only screen and (min-width: $media-width-medium) {
		font-size: 1.95em;
	}
}

.color-brand-blue {
	color: $brand-blue !important;
}
.color-white {
	color: $white !important;
}
.color-black {
	color: $black !important;
}

.bg-brand-blue {
	background-color: $brand-blue !important;
}
.bg-white {
	background-color: $white !important;
}
.bg-black {
	background-color: $black !important;
}

#root main {
	margin-top: 70px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "DM Sans", sans-serif;
}

a {
	cursor: pointer;
}

.scroll-disabled {
	touch-action: none;
}

/* Global Styles */
.text-vertical-center {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}

footer {
	padding: 100px 0;
}

img {
	width: 100%;
	height: auto;
}

.link-brand {
	border-bottom-color: lighten($brand-blue, 20%);
	color: lighten($brand-blue, 20%);
	border-bottom-style: solid;
	border-bottom-width: 1px;
	text-decoration: none;
	font-family: 'Open Sans', sans-serif;
	transition: all 0.3s ease;
}
